import learning1 from "../images/learning/nauka_ikona1.png";
import learning2 from "../images/learning/nauka_ikona2.png";
import learning3 from "../images/learning/nauka_ikona3.png";

import new_germany from "../images/newProductPage/languages/DE.png";
import new_spanish from "../images/newProductPage/languages/ES.png";
import new_french from "../images/newProductPage/languages/FR.png";
import new_italy from "../images/newProductPage/languages/IT.png";
import new_niderland from "../images/newProductPage/languages/ND.png";
import new_polish from "../images/newProductPage/languages/PL.png";
import new_portugal from "../images/newProductPage/languages/PR.png";
import new_russia from "../images/newProductPage/languages/RU.png";
import new_england from "../images/newProductPage/languages/UK.png";

import screen1 from "../images/newProductPage/croatian/1.png";
import screen2 from "../images/newProductPage/croatian/3.png";
import screen3 from "../images/newProductPage/croatian/5.png";
import screen4 from "../images/newProductPage/croatian/7.png";
import screen5 from "../images/newProductPage/croatian/8.png";

import Gabor from "../images/newProductPage/hungarian/people/Gabor.png";
import Ildiko from "../images/newProductPage/hungarian/people/Ildiko.png";
import Istvan from "../images/newProductPage/hungarian/people/Istvan.png";
import Katalin from "../images/newProductPage/hungarian/people/Katalin.png";
import Maria from "../images/newProductPage/hungarian/people/Maria.png";
import Zoltan from "../images/newProductPage/hungarian/people/Zoltan.png";

import dlaczego1 from "../images/newProductPage/why/dlaczego_1.webp";
import dlaczego2 from "../images/newProductPage/why/dlaczego_2.webp";
import dlaczego3 from "../images/newProductPage/why/dlaczego_3.webp";

import zdjecie2 from "../images/newProductPage/mission/latop_square.png";
import zdjecie3 from "../images/newProductPage/mission/people_square.png";
import zdjecie1 from "../images/newProductPage/mission/women_square.png";

export const croatian = {
  navbar: {
    data: [
      { title: "Naručite tečaj", path: "/course" },
      { title: "Prijava", path: "/start" },
      { title: "Verbalia", path: "/" },
      { title: "O nama", path: "/about" },
    ],
  },
  mainPage: {
    product_page: {
      hero: {
        languages: [
          "engleskom",
          "njemačkom",
          "talijanskom",
          "francuskom",
          "španjolskom",
          "nizozemskom",
          "ruskom",
          "poljskom",
          "portugalskom",
          // "grčkom",
          // "švedskom",
          // "norveškom",
        ],
        title: "Govorite hrabrije, sigurnije  na",
        prefix: "",
        subtitle: `Pouzdano govorite bilo koji jezik!Brzo steknite samopouzdanje uz lekcije usmjerene na razgovore iz stvarnog života.
        `,
        buttonText: "Naručite tečaj!",
      },
      labelInfo: {
        number: "9",
        numberDescription: "jezici",
        itemTwo: "14 000 recenzija",
        itemThree: {
          top: "Preko 200 tisuća",
          bottom: "polaznika",
        },
      },
      learning: {
        title: "Kako izgleda tečaj?",
        config: [
          {
            category: "Naučite jezik na jednostavan način",
            description: `Stotine lekcija s primjerima jezika koji se koristi u stvarnom životu.
              Praktične fraze koje se uče na tečajevima koje su osmislili izvorni govornici.
              Vježbe učinkovito podučavaju vještine čitanja, slušanja i govora.
              `,
          },
          {
            category: "Lekcije iz stvarnog života",
            description:
              "Svaka razina učenja sastoji se od desetak lekcija. Ako ne znate na kojoj ste razini – počnite s osnovama. Naučite pozdrave, dane u tjednu, kako naručiti jelo u restoranu. Svaka lekcija uključuje primjere iz svakodnevnog života.",
          },
          {
            category: "Vježbe",
            description:
              "Bez obzira volite li svakodnevno rješavati križaljke ili, naprotiv, radije izbjegavate takve aktivnosti, ne može se poreći da su ove mentalne zagonetke izvrsne za vježbanje vašeg pamćenja i omogućavaju vam da ponovite naučeno gradivo.",
          },
          {
            category: "Riječi, fraze i izrazi",
            description:
              "Svaka lekcija je popraćena pažljivo odabranim slikama koje proces učenja čine intuitivnim i kontekstualnim. Najčešće korištene teme pokrivaju širok spektar vokabulara iz svakodnevnog života i putovanja, što omogućuje komunikaciju u tim područjima bez tzv. jezične blokade.",
          },
        ],
        photoData: [screen1, screen2, screen3, screen4, screen5],
      },
      languages: {
        languages: [
          { image: new_england, lang: "English" },
          { image: new_french, lang: "French" },
          { image: new_spanish, lang: "Spanish" },
          { image: new_niderland, lang: "Dutch" },
          { image: new_germany, lang: "German" },
          { image: new_italy, lang: "Italian" },
          { image: new_russia, lang: "Russian" },
          { image: new_portugal, lang: "Portuguese" },
          { image: new_polish, lang: "Polish" },
          // { image: new_grece, lang: "grčki" },
          // { image: new_sweden, lang: "švedski" },
          // { image: new_norway, lang: "norveški" },
        ],
        title: "Dostupni jezici:",
        description: `Pripremite se za svoje sljedeće putovanje, dodajte jezične vještine u svoj životopis ili zaronite u novu kulturu: istražite opsežnu zbirku lekcija Verbalia i naučite što vam je potrebno da počnete govoriti, bez obzira na razinu vašeg iskustva.`,
      },
      success: {
        title: "Uspješne priče",
        subtitle: `Pogledajte kako drugi postaju tečni s Verbalia`,
        config: [
          {
            title: "",
            name: "Tonka",
            comment: `Bilo da ste početnik koji tek počinje učiti osnove ili napredni učenik koji želi proširiti svoj vokabular, Verbalia ima za svakoga ponešto. Jedna od stvari koje volim kod ove platforme je raznolikost lekcija, od naručivanja obroka u restoranu do naručivanja taksija, što čini iskustvo učenja realističnim i praktičnim. Dodatno, uključivanje fraza iz stvarnog života i snimaka za pravilan izgovor veliki je plus`,
            photo: Ildiko,
          },
          {
            title: "",
            name: "Milena",
            comment: `Sviđa mi se kako su Verbalia lekcije prilagođene situacijama iz stvarnog života, što mi olakšava primjenu onoga što sam naučila u stvarnom svijetu. Nakon 3 mjeseca učenja, ponuđen mi je novi posao!`,
            photo: Katalin,
          },
          {
            title: "",
            name: "Teo",
            comment: `Verbalia je izvrsna platforma za učenje jezika. Raznolikost lekcija i vježbi održava proces učenja zanimljivim i privlačnim. Zahvaljujući učenju jezika preko interneta sam upoznao djevojku iz Njemačke s kojom se uskoro vjenčam Preporučujem `,
            photo: Istvan,
          },
          {
            title: "",
            name: "Zdravko",
            comment: `Po mom mišljenju, Verbalia je nevjerojatna platforma za svakoga tko želi poboljšati svoje jezične vještine. Platforma je dizajnirana na takav način da učenje novog jezika čini ugodnim iskustvom.`,
            photo: Zoltan,
          },
          {
            title: "",
            name: "Viktor",
            comment: `„Koristim Verbalia već nekoliko mjeseci i sa sigurnošću mogu reći da mi je promijenio život. Dobio sam unapređenje na poslu! Uvijek sam bio zainteresiran za učenje novog jezika, ali nikad nisam imao vremena ili sredstava za to. Uz Verbalia mogu učiti vlastitim tempom i prema vlastitom rasporedu.`,
            photo: Gabor,
          },
          {
            title: "",
            name: "Hana",
            comment: `Verbalia koristim već nekoliko mjeseci i moram reći da mi je promijenio pravila igre. Kao netko tko je oduvijek bio zainteresiran za učenje novog jezika, ali nikada nije imao priliku za to, Verbalia mi je učinio učenje tako lakim i praktičnim. Sada mogu razumjeti i komunicirati s ljudima iz različitih kultura, što mi je proširilo perspektivu i otvorilo nove mogućnosti`,
            photo: Maria,
          },
        ],
      },
    },
    course: {
      title: "What Does a Course Look Like?",
      right:
        "On the Verbalia platform, you won't find boring, dry grammatical rules that you have to learn in a day. You learn sentences and expressions used in everyday life, then consolidate them in memory with simple exercises and repetitions. The goal of the course is to make learning fun.",
      right2Title: "Real Lessons",
      right2Bottom:
        "Learning at every level consists of dozens of lessons. If you don't know your level - start with the basics. Learn greetings, days of the week, your feelings. Learn how to order food in a restaurant, and how to order a taxi. Every lesson includes examples from everyday life. Each lesson is a little different, consisting of various tasks and exercises, but each forms a whole – so you can easily master the language. You can start the lesson anytime.",
      flexDown: [
        {
          title: "During learning, you will encounter elements such as:",
          text: `
      Audio recordings teaching correct pronunciation,
    `,
          text1: "Expressions and sentences from real life,",
          text2: "Images that help you visually associate ",
          text3: "and remember words and expressions,",
          text4: "...and many others.",
        },
        {
          title: "Tasks",
          text: `Regardless of whether you like solving puzzles daily or, on the contrary, prefer to avoid such activities, it is undeniable that these puzzles are excellent for developing memory and allow for the repetition of learned material.`,
        },
        {
          title: "Repetitions",
          text: `More advanced learners can repeat vocabulary in continuous learning mode and test their knowledge in every subject area. It includes all available vocabulary, from every category. A learning algorithm constantly checks the level of knowledge and adjusts the following lessons accordingly.`,
        },
      ],
    },
    learningWithVerbalia: {
      title: "Learning on the Verbalia Language Platform:",
      cards: [
        {
          icon: learning1,
          headline: `High Quality`,
          text: `
      During the development of Verbalia, an expert team set one of the most important goals: to make learning a foreign language as fast as possible with our method (regardless of the learner's age!).
        `,
        },
        {
          icon: learning2,
          headline: `Lessons Without Leaving Home`,
          text: `To use the Verbalia learning app, all you need is your phone, computer, or tablet. With Eureka, you can learn anywhere: in line, on the bus, and most importantly, at home without having to go anywhere. Learning while bathing or walking the dog? With Eureka, it's possible!
      `,
          text2:
            "Learning with Verbalia saves a lot of commuting time and you can easily integrate learning into your schedule!",
        },
        {
          icon: learning3,
          headline: `Short, but Systematic Lessons`,
          text: `The most important thing in language learning is regularity. At the same time, it's hard for all of us to find the necessary time. With this in mind, Eureka is designed to teach in short but systematic lessons.`,
        },
      ],
    },
    whyVerbalia: {
      title: "What Does a Course Look Like?",
      textUp:
        "This is a multifaceted learning approach platform that helps you master a new language as naturally as your mother tongue.",
      textBottom:
        "Learning a foreign language can change your life. It not only opens a window to a world full of professional and personal opportunities but also develops your mind and boosts your confidence. As technologies like the internet reduce distances and we become members of a global community, the ability to learn languages becomes an essential part of our interaction with the world and our success.",
      cards: [
        {
          headline: `Learn expressions and phrases from everyday life with our advanced learning technology.`,
          text: `The lessons are continuously adjusted to your level, so you never run out of boredom or motivation.`,
        },
        {
          headline: `Immerse yourself in a foreign language. Try to fully understand a new language based on your contextual knowledge.`,
          text: `With thousands of graphics and audio recordings, you will see and hear like a native speaker.`,
        },
        {
          headline: `Gain confidence in speaking. The real goal of language learning is also to understand.`,
          text: `With Eureka, all you have to do is repeat the vocabulary you hear - don't be afraid to speak! Our system will correct you.`,
        },
        {
          headline: `Don't let words slip out of your memory. Learn through various exercises.`,
          text: `We have carefully designed the app's elements so that your mind never gets bored. We are constantly preparing and expanding Eureka with new challenges to make learning fun.`,
        },
      ],
    },
  },
  aboutUs: {
    hero: {
      title: "Misija",
      description: `Naš krajnji cilj u Verbalia je omogućiti svakome pristup osobnom učitelju putem tehnologije. Vjerujemo da je jezik most između kultura i naša je misija prevladati komunikacijske barijere i zbližiti ljude kroz jezično obrazovanje.`,
    },
    why: {
      title: "Zašto mi?",
      config: [
        {
          title: "Inovativne metode poučavanja",
          image: dlaczego1,
          description:
            "Naš sofisticirani i u praksi provjereni algoritam učenja prilagođava materijale za učenje vašoj trenutnoj razini znanja i proširuje ga kroz učinkovito učenje",
        },
        {
          title: "Širok raspon jezika",
          image: dlaczego2,
          description:
            "Nudimo tečajeve na svjetski najraširenijim jezicima. To vam omogućuje da odaberete jezik koji odgovara vašim potrebama.",
        },
        {
          title: "Obrazovanje na zabavan način",
          image: dlaczego3,
          description:
            "Naša platforma čini učenje jezika zabavnim i ugodnim iskustvom, tako da ćete biti motivirani da ostanete s njim.            ",
        },
      ],
    },
    mission: {
      config: [
        {
          title: "Personalizacija i pristup",
          description:
            "Naš moćni algoritam strojnog učenja neprestano radi na pružanju materijala za učenje na odgovarajućoj razini za svakog pojedinca. Verbalia omogućuje učenicima da sami pronađu obrasce bez potrebe da se fokusiraju na pravila jezika - na isti način na koji ste učili svoj materinji jezik kao dijete.",
          image: zdjecie1,
        },
        {
          title: "Odvažna kombinacija tehnologije i znanosti          ",
          description:
            "Verbalia koristi odvažnu kombinaciju tehnologije i znanosti kako bi potaknuo vaš um na akciju. Ponavljanje bezbrojnih popisa riječi i fraza dosadno je i vaš mozak to zna. Naša metoda, koja vam pomaže da stečeno znanje koristite na različite načine u određenim trenucima, je uzbudljiva i učinkovita - vaš mozak će to cijeniti!",
          image: zdjecie2,
        },
        {
          title: "Zajednica i vrijednost",
          description:
            "Jezici pomažu ljudima da budu aktivni. Učenje jezika potiče razumijevanje. Verbalia zajednica okuplja ljude iz cijelog svijeta. Ambiciozni smo i uvijek pomičemo granice. Vjerujemo da bi učenje jezika trebalo biti zabavno i radoznali smo o istraživanju novih kultura kroz jezike.",
          image: zdjecie3,
        },
      ],
    },
  },
  formSender: {
    formControll: {
      pageLink: "https://nyelvfejlesztesi-kozpont.eu/",
      title: "Da li ste zainteresirani tečajem?",
      title1: "Trebate više informacija?",
      subtitle: "Ostavite nam svoj broj - nazvat ćemo vas",
      subtitle1:
        "If you already have an account, please fill in the following fields:",
      button1: "Ime i prezime",
      button2: "Broj telefona",
      button3: "E-mail",
      buttonSub: "Zatražite poziv",
      checkOne:
        "Slanjem ovog obrasca slažem se s uvjetima i izjavljujem da sam pročitao uvjete i pravila zaštite podataka.        ",
      checkOneTwo: "the",
      checkTwo: "Slažem se da me se kontaktira u marketinške svrhe",
      textOne: "Obavještavamo vas da",
      textTwo:
        "Administrator Vaših osobnih podataka je EOU Sp. z o.o., sa sjedištem u Varšavi, ul. Młynarska 42 / 115, 01-171, registrirano u registru poduzetnika koji vodi okružni sud u Varšavi pod KRS 0001038089, REGON: 525396900, NIP 5273057993. Vaše osobne podatke obrađivati ćemo u vezi s upitom putem kontakt forme ili izvršenjem sklopljenog ugovora. Obrativši nam se, dobrovoljno pristajete na obradu Vaših osobnih podataka u gore navedene svrhe. Imate pravo pristupiti, ispraviti, izbrisati ili ograničiti obradu svojih podataka, prigovoriti obradi i prenosivosti podataka. Također imate pravo povući svoju privolu u bilo kojem trenutku, ali povlačenje privole ne utječe na zakonitost obrade temeljene na privoli prije njenog povlačenja. Također imate pravo podnijeti pritužbu nadzornom tijelu. Pružanje podataka je dobrovoljno, ali može biti potrebno za odgovor na upit ili izvršenje ugovora. Osobni podaci bit će pohranjeni onoliko koliko je potrebno za gore navedene svrhe, ali ne dulje od roka zastare potraživanja prema općem pravu. Molimo pažljivo pročitajte politiku privatnosti. ",
      buttonMore: "Čitaj više",
      buttonLess: "Čitaj manje",
      subOne: "uvjeti pružanja usluge",
      subTwo: "i pravila privatnosti.",
      buttonTXT: "Thank you",
      rulesLink: "/Regulations_HR.pdf",
      privacyLink: "/Privacy_HR.pdf",
      product: "eh",
      market: "hr",
      send_to_url: "https://crm.europa-edu.eu/api/v1/cms/create_order",
      utm_source: "Platform_eLingo",
      product_variant: "hr-en",
      server_link: "https://cms.europa-assets.eu/integration/lead-from-landing",
      currency: "eur",
      price: 87,
    },
  },
  footer: {
    title: "Pridružite nam se na:",
    privacy: "Privatnost",
    privacyLink: "/Privacy_HR.pdf",
    rules: "Uvjeti pružanja usluge",
    rulesLink: "/Regulations_HR.pdf",
  },
};
